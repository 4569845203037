import { StatesManagementService } from '@medlogic/medlogic/medlogic-state';
import { cleanGeneric } from '@medlogic/shared/ui/ui-generic-list';
import { UiDialogAlertComponent } from '@medlogic/shared/ui/dialog/ui-dialog-alert';
import { RedisService } from '@medlogic/shared/shared-data-access';
import { MedLogicNavigationService } from '@medlogic/medlogic/medlogic-navigation';
import { IMenuItem, EnViewMode } from '@medlogic/shared/shared-interfaces';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { DialogConfirmComponent } from '@medlogic/shared/ui/dialog/ui-dialog-confirm';
import { UnsubscribeOnDestroyAdapter, LogService, LocalLibService, EnTheme } from '@medlogic/shared/shared-interfaces';
import { logOut, selectTenantTheme, setTenantTheme } from '@medlogic/medlogic/medlogic-state';
import {
  selectAppTitle, selectEnViewMode, selectIsMobile,
  setIsMobile, selectIsLoading
} from '@medlogic/medlogic/medlogic-state';
import { fadeAnimation } from '@medlogic/shared/utils';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Howl } from 'howler';

@Component({
  selector: 'ml-root',
  templateUrl: './app-sistema-medlogic.component.html',
  styleUrls: ['./app-sistema-medlogic.component.scss'],
  animations: [fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSistemaMedlogicComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  title$: Observable<string>;
  enViewMode$ = this.store.select(state => selectEnViewMode(state));
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  isLoading$ = this.store.pipe(select(selectIsLoading));
  isMobile$ = this.store.pipe(select(selectIsMobile));
  enTheme$ = this.store.pipe(select(selectTenantTheme));
  userLogin$: Observable<string> = this.store.pipe(select(state => state?.login?.userName));
  showFooter$ = this.router.events
    .pipe(
      map(m => {
        const path = this.location?.path();
        const hasMenu = this.pagesWithMenu?.length > 0 && this.pagesWithMenu?.reduce((a, b) => a || path?.includes(b), false);
        if (hasMenu) {
          this.selectedOption = this.pagesWithMenu.reduce((a, b) => path?.includes(b) ? b : a, 'process');
          return true;
        }
        return false;
      })
    );

  isDesktop = false;
  isDark = false;

  clickedItem: string;
  pagesWithMenu = [];
  selectedOption = 'home';
  menuItems = [
    { name: 'Home', icon: 'home', link: '/exam/person/list', click: this.onHomeClick, active: true, isPersonDependent: false } as IMenuItem,
    { name: 'Tutorial', icon: 'school', link: '/exam/tutorial', active: false, isPersonDependent: false } as IMenuItem,
    { name: 'IVCF-20', icon: 'format_list_bulleted', link: '/exam/frailty', active: true, isPersonDependent: true } as IMenuItem,
    { name: 'Exames', icon: 'analytics', link: '/exam', active: true, isPersonDependent: true } as IMenuItem,
    { name: 'Evolução', icon: 'timeline', link: '/person/evolution/list', active: false, isPersonDependent: true } as IMenuItem,
    { name: 'Prescrição', icon: 'history', link: '/person/history', active: false, isPersonDependent: true } as IMenuItem,
    { name: 'Contato', icon: 'contact_support', link: '/user/contact', active: false, isPersonDependent: false } as IMenuItem,
  ];

  ENVIEWMODE = EnViewMode;
  ENTHEME = EnTheme;

  constructor(
    private router: Router,
    private location: Location,
    private breakpointObserver: BreakpointObserver,
    private log: LogService,
    private store: Store<IAppMedlogicState>,
    private nav: MedLogicNavigationService,
    private matDialog: MatDialog,
    private lib: LocalLibService,
    private redis: RedisService,
    private stateMan: StatesManagementService
  ) {
    super();
  }

  ngOnInit(): void {
    try {
      this.title$ = this.store.select(state => selectAppTitle(state as IAppMedlogicState));

      this.subs.sink = this.isMobile$.pipe(
        withLatestFrom(this.enTheme$),
        tap(([isMobile, enTheme]) => {
          this.isDesktop = !isMobile;
          this.isDark = enTheme === EnTheme.black

          document.body.classList.toggle('black', enTheme === EnTheme.black);
          document.body.classList.toggle('mobile', isMobile);
        })
      ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  play(url: string): void {
    try {
      const sound = new Howl({
        src: [url]
      });
      sound.play();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'play', error.message);
    }
  }

  onMenuSelection(menuItem: IMenuItem): void {
    try {
      this.clickedItem = menuItem?.name;
      let currentOption = {} as IMenuItem;
      currentOption = this.menuItems?.find(option =>
        option?.name?.localeCompare(menuItem?.name?.toLowerCase())
      );
      currentOption.active = true;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onMenuSelection', error.message);
    }
  }

  onExitClick($event: any): void {
    try {
      const width = '100%';
      const height = '250px';
      const dialogRef = this.matDialog
        .open(DialogConfirmComponent,
          {
            width,
            maxWidth: '520px',
            height,
            data: {
              title: 'Tem certeza que pretende sair?'
            }
          });
      this.subs.sink = dialogRef.afterClosed()
        .subscribe(wasConfirmed => {
          if (wasConfirmed) {
            this.store.dispatch(logOut());
            this.store.dispatch(cleanGeneric());
            this.nav.navigateToLogin();
          }
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onExitClick', error.message);
    }
  }

  onHomeClick($event: any, drawer: any): void {
    try {
      drawer.toggle();
      this.nav.navigateToRoot();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onHomeClick', error.message);
    }
  }

  onFullScreenClick($event: any, drawer: any): void {
    try {
      drawer.toggle();
      this.lib.toogleFullscreen();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onFullScreenClick', error.message);
    }
  }

  onCacheCleanClick($event: any, drawer: any): void {
    try {
      drawer.toggle();
      this.subs.sink = this.redis.deleteCustomerKeys()
        .pipe(
          tap(() => this.stateMan.cleanAllStates())
        )
        .subscribe((res: boolean) => {
          const messageHtml = res ? 'Cache removido com sucesso!' : 'Houve falha na tentativa de limpeza do cache';
          const width = '100%';
          const height = '250px';
          this.matDialog
            .open(UiDialogAlertComponent,
              {
                width,
                maxWidth: '520px',
                height,
                data: {
                  title: 'Limpeza de Cache',
                  messageHtml
                }
              });
          this.nav.navigateToRoot();
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onCacheCleanClick', error.message);
    }
  }

  onDesktopChange($event: any, drawer: any): void {
    try {
      const isMobile = !$event?.checked;

      document.body.classList.toggle('mobile', isMobile);
      localStorage.setItem('mode', isMobile ? 'mobile' : 'desktop');

      this.store.dispatch(setIsMobile({ isMobile }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onDesktopChange', error.message);
    }
  }

  onDarkModeChange($event: any, drawer: any): void {
    try {
      const enTheme = $event?.checked ? EnTheme.black : EnTheme.default;

      document.body.classList.toggle('black', enTheme === EnTheme.black);
      localStorage.setItem('theme', enTheme);

      this.store.dispatch(setTenantTheme({ enTheme }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onDarkModeChange', error.message);
    }
  }


}
